import { ActionIcon, Button, Flex, Group, LoadingOverlay, Modal, Tabs, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconArrowLeft, IconClock, IconCoins, IconInfoSquare, IconPrinter } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import useSwr from "swr";
import { InvoiceApi } from "../../apis";
import { MultiCollapse } from "../../components/MultiCollapse/multi-collapse";
import { BuyerAttachmentDetailsForm } from "../../components/buyer-invoice-details/buyer-attachment-details-form";
import { BuyerProductDetailsForm } from "../../components/buyer-invoice-details/buyer-product-details-form";
import { BuyerReceiverDetailsForm } from "../../components/buyer-invoice-details/buyer-receiver-details-form";
import { BuyerSenderDetailsForm } from "../../components/buyer-invoice-details/buyer-sender-form";
import { BuyerInvoicePayForm } from "../../components/buyer-invoice-pay/buyer-invoice-pay-form";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { message } from "../../utils/Message";
import { InvoicePaidHistory } from "./invoice-paid-history";

type IFileItem = {
  name: string;
  description: string;
  url: string;
};

type IItem = {
  variantId: string;
  variantUnitId: string;
  discountType: string;
  discountValue: number | string;
  hasVat: boolean;
  hasTax: boolean;
  quantity: number;
};

type IFormData = {
  senderStaffId: string | undefined;
  senderBranchId: string | undefined;
  senderAccId: string | undefined;
  receiverBusinessId: string | undefined;
  paymentDate: Date | string | undefined;
  receiverBranchId: string | undefined;
  description: string | undefined;
  discountType: string | undefined;
  discountValue: number;
  shippingAmount: number;
  lines: IItem[];
  send: boolean;
  attachments: IFileItem[];
  invoiceStatus?: any;
  refCode: string;
  orderSalesCode: string | null;
  purchaseSalesCode: string | null;
};

export const InvoiceDetails = () => {
  const params = useParams();
  const [action, setAction] = useState<any[]>([]);
  const navigate = useNavigate();
  const INV_RES = usePermission("INV_RES");
  const INV_PAY_QPAY = usePermission("INV_PAY_QPAY");
  const INV_PAY_B2B = usePermission("INV_PAY_B2B");

  const [tabKey, setTabKey] = useState<string>("details");
  const { theme } = userStyles();

  const { data, isLoading } = useSwr<IFormData>(
    params.id !== "new" ? `/api/invoice/${params.id}` : null,
    params.id !== "new" ? async () => await InvoiceApi.get(`${params.id}`) : null,
    {
      fallback: {
        invoiceStatus: undefined,
        senderStaffId: undefined,
        senderBranchId: undefined,
        senderAccId: undefined,

        receiverBusinessId: undefined,
        paymentDate: undefined,
        receiverBranchId: undefined,

        description: undefined,
        discountType: undefined,
        discountValue: 0,
        shippingAmount: 0,
        lines: [],
        send: false,
        attachments: [],
        refCode: "",
        orderSalesCode: null,
        purchaseSalesCode: null,
        additionalLines: [],
      },
    },
  );
  const breadcrumbs = useBreadcrumb();

  const onRespond = async (confirm: boolean) => {
    try {
      await InvoiceApi.respond(params.id || "", { confirm, respondText: "reject" });
      if (confirm) message.success("Нэхэмжлэл амжилттай баталлаа.");
      else message.success("Нэхэмжлэл амжилттай цуцаллаа.");
      navigate("/buyer-invoice");
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const tabOptions = [
    {
      children: "Нэхэмжлэх мэдээлэл",
      value: "details",
      disabled: false,
      icon: (key: string) => <IconInfoSquare size={22} color={tabKey === key ? theme.colors.brand[6] : "black"} />,
    },
    {
      children: "Төлөлтийн түүх",
      value: "paid_history",
      disabled: false,
      icon: (key: string) => <IconClock size={22} color={tabKey === key ? theme.colors.brand[6] : "black"} />,
    },
  ];

  if (isLoading) return <LoadingOverlay visible />;
  return (
    <PageLayout
      title={
        params?.action === "review" ? (
          <Flex gap={8} align="center">
            <Text>Нэхэмжлэх хянах</Text>
            <Text color="brand">#{data?.refCode}</Text>
          </Flex>
        ) : (
          <Flex gap={8} align="center">
            <ActionIcon onClick={() => navigate(-1)} variant="outline" size="sm" color="brand">
              <IconArrowLeft />
            </ActionIcon>
            <Text> Нэхэмжлэх дэлгэрэнгүй </Text> <Text color="brand">#{data?.refCode}</Text>
          </Flex>
        )
      }
      // subTitle={params?.action === "review" ? "Борлуулалтын нэхэмжлэх хянах" : "Борлуулалтын нэхэмжлэх дэлгэрэнгүй"}
      breadcrumb={breadcrumbs}
      extra={
        data?.invoiceStatus === "SENT" && params?.action === "review"
          ? [
              <Group key={0} hidden={!INV_RES.hasAccess}>
                <Button
                  variant="light"
                  color="red"
                  onClick={() => {
                    onRespond(false);
                  }}>
                  Татгалзах
                </Button>
                <Button
                  onClick={() => {
                    onRespond(true);
                  }}>
                  Батлах
                </Button>
              </Group>,
            ]
          : [
              <Group key={1} hidden={!INV_PAY_B2B.hasAccess && !INV_PAY_QPAY.hasAccess}>
                {data?.invoiceStatus === "CONFIRMED" && (
                  <>
                    <Button
                      disabled={data?.invoiceStatus !== "CONFIRMED"}
                      leftSection={<IconCoins />}
                      onClick={() => {
                        setAction(["pay", data]);
                      }}>
                      Төлөлт хийх
                    </Button>
                    <Button px={"xs"} variant="light">
                      <IconPrinter />
                    </Button>
                  </>
                )}
              </Group>,
            ]
      }>
      <Tabs
        onChange={(e: string | null) => {
          if (e) setTabKey(e);
        }}
        value={tabKey}>
        <Tabs.List>
          {tabOptions.map((item: { children: string; disabled: boolean; value: string; icon: any }, index: number) => {
            return (
              <Tabs.Tab color="brand" p={"xs"} value={item.value} key={index} disabled={item.disabled}>
                <Flex align="center" gap={8}>
                  {item.icon(item.value)}
                  <Text fw={500} size={"sm"} color={tabKey === item.value ? "brand" : "black"}>
                    {item.children}
                  </Text>
                </Flex>
              </Tabs.Tab>
            );
          })}
        </Tabs.List>

        <Tabs.Panel value="details" pt="xs">
          <MultiCollapse loading={isLoading} defaultCollapse={[0, 1, 2, 3, 4]} option={option({ data })} />
        </Tabs.Panel>

        <Tabs.Panel value="paid_history" pt="xs">
          <InvoicePaidHistory data={data} />
        </Tabs.Panel>
      </Tabs>
      <Modal padding={0} withCloseButton={false} size="1000px" centered title={false} opened={action[0] === "pay"} onClose={() => setAction([])} radius="sm">
        <BuyerInvoicePayForm action={action} setAction={setAction} />
      </Modal>
    </PageLayout>
  );
};

const option = ({ data }: any) => [
  {
    title: "Нэхэмжлэх илгээж буй тал",
    subTitle: "Нэхэмжлэх илгээж буй партнер байгууллагын мэдээлэл",
    children: <BuyerSenderDetailsForm values={{ ...data, lines: data?.lines || [] }} />,
  },
  {
    title: "Худалдан авагч",
    subTitle: "Нэхэмжлэхийн төлбөр төлөгч байгууллагын мэдээлэл",
    children: <BuyerReceiverDetailsForm values={{ ...data, lines: data?.lines || [] }} />,
  },
  {
    title: "Нэхэмжлэхийн зүйл",
    subTitle: "Нэхэмжлэхийн зүйл, дэлгэрэнгүй мэдээлэл",
    children: <BuyerProductDetailsForm values={{ ...data, lines: data?.lines || [] }} />,
  },
  {
    title: "Хавсралт файлууд",
    subTitle: "Хавсаргасан файлын жагсаалт, дэлгэрэнгүй мэдээлэл",
    children: <BuyerAttachmentDetailsForm values={{ ...data, lines: data?.lines || [] }} />,
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/buyer-invoice",
    label: "Нээлттэй нэхэмжлэх",
  },
  {
    label: "Нэхэмжлэх",
  },
];

const userStyles = createStyles(() => ({}));
