import { ActionIcon, Anchor, Avatar, Box, Divider, Flex, Group, SegmentedControl, Tabs, Text, Tooltip } from "@mantine/core";
import { IconCreditCard, IconEyeCheck, IconFileTypePdf, IconInfoSquare, IconPhoto } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InvoiceApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IItem } from "../../interfaces/IItem";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";

export const BuyerInvoiceList = () => {
  const breadcrumbs = useBreadcrumb();
  const { invoiceStatus = [], invoicePaymentStatus = [] } = useSelector((state: { general: IGeneral }) => state.general);
  const navigate = useNavigate();
  const { myBusinessType } = useSelector((state: { auth: IAuth }) => state.auth);
  const { accessDenied, hasAccess } = usePermission("INV_LIST");

  const [load, setLoad] = useState<string[]>([]);
  const [filters, setFilters] = useState({
    query: "",
    status: "",
  });

  const Pdf = async (id: string) => {
    let res = await InvoiceApi.pdf(id);
    setLoad([...load.filter((item: string) => item !== id)]);
    window.open(res);
  };

  const columns = useHeader({
    myBusinessType,
    invoicePaymentStatus: invoicePaymentStatus,
    invoiceStatus: invoiceStatus,
    load,
    onClick: (key, record) => {
      if (key === "detail") navigate(`/buyer-invoice/${record?.id}/details`);
      if (key === "review") navigate(`/buyer-invoice/${record?.id}/review`);
      if (key === "pay") navigate(`/buyer-invoice/${record?.id}/pay`);
      if (key === "pdf") {
        setLoad([...load, `${record.id}`]);
        Pdf(`${record.id}`);
      }
    },
  });

  return (
    <PageLayout title="Нээлттэй нэхэмжлэх" subTitle="Нээлттэй нэхэмжлэх" breadcrumb={breadcrumbs}>
      <Tabs variant="pills" defaultValue="1">
        <SegmentedControl
          key={1}
          onChange={(e) => setFilters({ ...filters, status: e })}
          value={filters.status}
          data={[{ name: "Бүгд", code: "" }, ...invoiceStatus]?.map((item: any, index: number) => {
            return {
              value: item?.code,
              label: (
                <Box style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  {index !== 0 && <Box style={{ width: 8, height: 8, borderRadius: "100%", background: `${item?.color}` }} />}
                  {item?.name ?? "-"}
                </Box>
              ),
            };
          })}
        />
        <Divider mt="md" mb="md" />
        <Tabs.Panel value="1">
          {hasAccess ? <Table name="InvoiceApi.sent" columns={columns} filters={filters} loadData={(data) => InvoiceApi.received(data!)} /> : accessDenied()}
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
};

const useHeader = ({
  onClick,
  myBusinessType = "",
  invoicePaymentStatus = [],
  invoiceStatus = [],
  load = [],
}: {
  onClick: (key: string, record: IItem) => void;
  myBusinessType: string;
  invoicePaymentStatus: IReference[];
  invoiceStatus: IReference[];
  load: string[];
}): ColumnType<IItem | any>[] => [
  {
    title: "Үйлдэл",
    align: "right",
    width: "120px",
    render: (record) => {
      return (
        <Flex gap={8} align="center">
          <Tooltip label="Дэлгэрэнгүй">
            <ActionIcon onClick={() => onClick("detail", record)} variant="light" color="blue">
              <IconInfoSquare />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Төлөх">
            <ActionIcon onClick={() => onClick("pay", record)} variant="light" color="green" disabled={record.invoiceStatus !== "CONFIRMED"}>
              <IconCreditCard />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="PDF">
            <ActionIcon loading={load.some((item: string) => item === record.id)} onClick={() => onClick("pdf", record)} variant="light" color="indigo">
              <IconFileTypePdf />
            </ActionIcon>
          </Tooltip>
          {record.invoiceStatus === "SENT" && (
            <Tooltip label="Хянах">
              <ActionIcon onClick={() => onClick("review", record)} variant="light" color="brand">
                <IconEyeCheck />
              </ActionIcon>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Нэхэмжлэх дугаар",
    dataIndex: "refCode",
    render: (record) => {
      return <Text size="sm">#{record?.refCode ?? "-"}</Text>;
    },
    width: "100px",
  },
  {
    title: "Нийлүүлэгч бизнес",
    dataIndex: "category",
    render: (record) => (
      <Flex gap={"sm"} align={"center"} w={"max-content"}>
        <Avatar src={record?.senderBusiness?.logo} size={32} radius="xs">
          <IconPhoto />
        </Avatar>
        <div>
          <Text size="sm">{record?.senderBusiness?.profileName ?? "-"}</Text>
          <Anchor size="sm"># {record?.senderBusiness?.refCode ?? "-"}</Anchor>
        </div>
      </Flex>
    ),
  },
  {
    title: "Партнер нэр",
    dataIndex: "itemStatus",
    render: (record) => (
      <Group style={{ flex: 1 }} w="max-content">
        <div>
          <Text size="sm">{record?.senderBusiness?.partner?.businessName ?? "-"}</Text>
          <Anchor size="sm"># {record?.senderBusiness?.partner?.refCode ?? "-"}</Anchor>
        </div>
      </Group>
    ),
  },
  {
    title: "Баталсан дүн",
    dataIndex: "confirmedAmount",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end" c="orange.5">
        {record?.confirmedAmount ? currencyFormat(record.confirmedAmount) : "-"}
      </Text>
    ),
  },
  {
    title: "Төлсөн дүн",
    dataIndex: "paidAmount",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end" c="green.5" size="sm">
        {record.paidAmount ? currencyFormat(record.paidAmount ?? 0) : "-"}
      </Text>
    ),
  },
  {
    title: "Төлбөрийн үлдэгдэл",
    dataIndex: "amountToPay",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end" c="gray.5" size="sm">
        {currencyFormat(record?.amountToPay ?? 0)}
      </Text>
    ),
  },
  {
    title: "Төлбөрийн статус",
    dataIndex: "description",
    render: (record) => {
      return (
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <div>
            {Tag(
              invoicePaymentStatus.find((item: IReference) => item.code === record.paymentStatus)?.color ?? "",
              invoicePaymentStatus.find((item: IReference) => item.code === record.paymentStatus)?.name ?? record.paymentStatus,
            )}
          </div>
        </Box>
      );
    },
  },
  {
    title: "Үлдсэн хугацаа",
    dataIndex: "description",
    render: (record) => `${record?.remainingDays} хоног`,
  },
  {
    title: "Нэхэмжлэх төлөх огноо",
    dataIndex: "description",
    render: (record) => <Text size="sm">{record?.paymentDate ? dayjs(record?.paymentDate).format("YYYY-MM-DD hh:mm") : "-"}</Text>,
  },
  {
    title: "Нэхэмжлэхийн төлөв",
    dataIndex: "description",
    render: (record) => {
      return (
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <div>
            {Tag(
              invoiceStatus.find((item: IReference) => item.code === record.invoiceStatus)?.color ?? "",
              invoiceStatus.find((item: IReference) => item.code === record.invoiceStatus)?.name ?? record.invoiceStatus,
            )}
          </div>
        </Box>
      );
    },
  },
  {
    title: "Баталсан огноо",
    dataIndex: "description",
    render: (record) =>
      record?.confirmedDate ? (
        <Text w={"max-content"} size="sm">
          {dateTimeFormat(record?.confirmedDate)}
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Баталсан ажилтан",
    dataIndex: "description",
    render: (record) => {
      if (record?.confirmedUser)
        return (
          <Flex gap={10} align="center" w={"max-content"}>
            <Avatar component="a" src={record?.confirmedUser?.avatar} />
            <div>
              <Text size="sm" tt={"capitalize"}>
                {record?.confirmedUser?.lastName ? `${record?.confirmedUser?.lastName[0]}.` : ""}
                {record?.confirmedUser?.firstName ?? "-"}
              </Text>
            </div>
          </Flex>
        );
      return "-";
    },
  },
  {
    title: "Илгээсэн огноо",
    dataIndex: "description",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record?.sentDate ? dateTimeFormat(record.sentDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Илгээсэн ажилтан",
    dataIndex: "description",
    width: "520px",
    render: (record) => (
      <Flex gap={10} align="center" w="max-content">
        <Avatar component="a" src={record?.senderUser?.avatar} />
        <Text size="sm" tt={"capitalize"}>
          {record?.senderUser?.lastName ? `${record?.senderUser?.lastName[0]}.` : ""} {record?.senderUser?.firstName ?? "-"}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Бор.Зах дугаар",
    dataIndex: "salesOrder.refCode",
    render: (record) => {
      return <Text size="sm">{record?.salesOrder?.refCode ?? "-"}</Text>;
    },
  },
  {
    title: "Худ.авалт Зах.дугаар",
    dataIndex: "purchaseOrder.refCode",
    render: (record) => {
      return <Text size="sm">{record?.purchaseOrder?.refCode ?? "-"}</Text>;
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Нээлттэй нэхэмжлэх",
  },
];
