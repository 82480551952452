import { Avatar, Checkbox, Divider, Flex, Grid, Group, Input, Paper, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { ColumnType, Table } from "../table";

export function BuyerProductDetailsForm({ values }: { values: any }) {
  const { perTypes, vatTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [edit, setEdit] = useState<string>("");

  const columns = useHeader({ vatTypes, edit, setEdit });
  const columns2 = useHeader2({ perTypes, edit, setEdit });

  return (
    <Paper>
      <Flex direction="column" gap={14}>
        <Table columns={columns} name="values?.lines2" dataSource={values?.lines ?? []} />

        <Text fz={"lg"} fw={600}>
          Нэмэлтээр
        </Text>

        <Table columns={columns2} name="values?.additionalLines" dataSource={values?.additionalLines ?? []} />

        <Divider my="md" />

        <Grid>
          <Grid.Col span={6}>
            <Grid>
              <Grid.Col span={12}>
                <Input.Wrapper label="Тайлбар:">
                  <Paper>
                    <Text component="p" size="sm" c="dimmed" maw="600px" m={0}>
                      {values.description ?? "-"}
                    </Text>
                  </Paper>
                </Input.Wrapper>
              </Grid.Col>
            </Grid>
          </Grid.Col>

          <Grid.Col span={6}>
            <Paper p="md" withBorder>
              <Flex direction="column" gap={10}>
                <Group align="center" justify="flex-end">
                  <Text>Нийт барааны дүн</Text>
                  <Text>{currencyFormat(values?.itemsTotal ?? 0)}</Text>
                </Group>
                <Group align="center" justify="flex-end">
                  <Text>Тооцсон НӨАТ</Text>
                  <Text>{currencyFormat(values?.vatAmount ?? 0)}</Text>
                </Group>
                <Group align="center" justify="flex-end">
                  <Text>Тооцсон НХАТ</Text>
                  <Text>{currencyFormat(values?.taxAmount ?? 0)}</Text>
                </Group>
                <Group align="center" justify="flex-end">
                  <Text>Нэмэлт</Text>
                  <Text>
                    {currencyFormat(values?.additionalLines?.reduce((total: number, item: any) => total + (item?.quantity ?? 0) * (item?.price ?? 0), 0) ?? 0)}
                  </Text>
                </Group>
                <Group align="center" justify="flex-end">
                  <Text>Нийт дүнгийн хөнгөлөлт</Text>
                  <Text>{currencyFormat(values?.discountAmount ?? 0)}</Text>
                </Group>
                <Group align="center" justify="flex-end">
                  <Text>Хүргэлтийн төлбөр</Text>
                  <Text>{currencyFormat(values?.shippingAmount ?? 0)}</Text>
                </Group>
                <Group align="center" justify="flex-end">
                  <Text fw={500} c="indigo">
                    НИЙТ ТӨЛБӨР
                  </Text>
                  <Text fw={500} c="indigo">
                    {currencyFormat(values?.totalAmount ?? 0)}
                  </Text>
                </Group>
              </Flex>
            </Paper>
          </Grid.Col>
        </Grid>
      </Flex>
    </Paper>
  );
}

const useHeader = ({ vatTypes }: { vatTypes: IReference[]; edit: any; setEdit: any }): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Зураг",
    sorter: true,
    dataIndex: "image",
    render: (record) => (
      <Avatar src={`${record?.image ?? ""}`}>
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "SKU код",
    sorter: true,
    dataIndex: "skuCode",
    render: (record) => <Text size="sm">{record?.skuCode ?? "-"}</Text>,
  },
  {
    title: "Бараа, ажил үйлчилгээ",
    sorter: true,
    dataIndex: "nameMon",
    render: (record) => <Text size="sm">{record?.name ?? "-"}</Text>,
  },
  {
    title: "Хэмжих нэгж",
    sorter: true,
    dataIndex: "unit.name",
    render: (record) => <Text size="sm">{record?.unit ?? "-"}</Text>,
  },
  {
    title: "Нэгжийн үнэ",
    sorter: true,
    dataIndex: "price",
    render: (record) => (
      <Text ta="end" size="sm">
        {currencyFormat(record?.price ?? 0)}
      </Text>
    ),
  },
  {
    title: "НӨАТ",
    sorter: true,
    dataIndex: "hasVat",
    render: (record) => <Text size="sm">{currencyFormat(record?.vatAmount ?? 0)}</Text>,
  },
  {
    title: "НХАТ",
    sorter: true,
    dataIndex: "hasTax",
    render: (record) => <Checkbox disabled checked={record?.hasTax} onChange={() => {}} />,
  },
  {
    title: "Тоо хэмжээ",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text w={150} size="sm">
          {record.quantity ?? "0"}
        </Text>
      );
    },
  },
  {
    title: "Үнийн дүн",
    sorter: true,
    dataIndex: "name",
    render: (record: any) => {
      let count;
      count = ((record.vatAmount ?? 0) + (record?.price ?? 0)) * (record?.quantity ?? 0);
      return (
        <Text ta="end" size="sm">
          {currencyFormat(count ?? 0)}
        </Text>
      );
    },
  },
];

const useHeader2 = ({ perTypes }: { perTypes: IReference[]; edit: any; setEdit: any }): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Бараа, ажил үйлчилгээ",
    sorter: true,
    dataIndex: "nameMon",
    render: (record) => <Text size="sm">{record?.name ?? "-"}</Text>,
  },
  {
    title: "Хэмжих нэгж",
    sorter: true,
    dataIndex: "unit.name",
    render: (record) => <Text size="sm">{record?.unit ?? "-"}</Text>,
  },
  {
    title: "Нэгжийн үнэ",
    sorter: true,
    dataIndex: "price",
    render: (record) => (
      <Text ta="end" size="sm">
        {currencyFormat(record?.price ?? 0)}
      </Text>
    ),
  },
  {
    title: "Тоо хэмжээ",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text w={150} size="sm">
          {record.quantity ?? "0"}
        </Text>
      );
    },
  },
  {
    title: "Үнийн дүн",
    sorter: true,
    dataIndex: "name",
    render: (record: any) => {
      let count;
      count = ((record.vatAmount ?? 0) + (record?.price ?? 0)) * (record?.quantity ?? 0);
      return (
        <Text ta="end" size="sm">
          {currencyFormat(count ?? 0)}
        </Text>
      );
    },
  },
];
