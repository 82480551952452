import { Alert } from "@mantine/core";
import { IconUserShield } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";

export type IPermissionCode =
  | "INV_DASH"
  | "INV_LIST"
  | "INV_NOTE_PAY"
  | "INV_RES"
  | "INV_PAY_B2B"
  | "INV_PAY_QPAY"
  | "INV_CLOSED"
  | "INV_NET_SETT"
  | "INV_SETT"
  | "INV_SETT_SEND"
  | "INV_SETT_RES";

export function usePermission(code: IPermissionCode) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { permissions } = useSelector((state: { general: IGeneral }) => state.general);

  function accessDenied() {
    return (
      <Alert title={code && "Хандах эрх хүрэлцэхгүй байна!"} icon={<IconUserShield />} color="red">
        {permissions?.find((item: any) => item.code === code)?.name || "Хандах эрх хүрэлцэхгүй байна!"}
      </Alert>
    );
  }

  const hasAccess = user?.permissions?.some((som: any) => som.code === code) || false;
  const isCreate = user?.permissions?.some((item: any) => item.code === code && item.isCreate) || false;
  const isDelete = user?.permissions?.some((item: any) => item.code === code && item.isDelete) || false;
  const isEdit = user?.permissions?.some((item: any) => item.code === code && item.isEdit) || false;
  const isReview = user?.permissions?.some((item: any) => item.code === code && item.isReview) || false;
  const isView = user?.permissions?.some((item: any) => item.code === code && item.isView) || false;

  return {
    hasAccess,
    isCreate,
    isDelete,
    isEdit,
    isReview,
    isView,
    accessDenied,
  };
}
